
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { AxiosError } from 'axios';
import SidebarUsersForRate from '@/components/assessmentSurvey/SidebarUsersForRate.vue';
import SurveyAssessment from '@/components/assessmentSurvey/SurveyAssessment.vue';
import NoDataStub from '@/components/common/NoDataStub.vue';
import DialogAddingUsersForRate from '@/components/assessmentChecklist/DialogAddingUsersForRate.vue';
import {
  V1EntitiesCouplesPublicExpertIndexCouple,
  V1EntitiesCouplesPublicExpertIndexGroup,
  V1EntitiesAudiencesPublicSearchIndexAudience,
  V1EntitiesAssessmentObjectsPublicShowGroup,
  V1EntitiesAssessmentObjectsPublicShowQuestion,
  PostV1CouplesCoupleIdAssessmentAnswer,
  V1EntitiesAssessmentObjectsPublicShowAudience,
  V1EntitiesCouplesPublicExpertIndex,
} from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';
import { RatingStatusAliases } from '@/constants/userRatingStatuses';
import { SurveyStageKind } from '@/constants/surveyStage';
import { CoupleRejectComment } from '@/components/assessmentChecklist/types';
import { tsxassApi } from '@/services/api';

const ASSESSMENT_DEFAULT_VALUE = {
  groups: [] as V1EntitiesAssessmentObjectsPublicShowGroup[],
  questions: [] as V1EntitiesAssessmentObjectsPublicShowQuestion[],
  surveyee: null as V1EntitiesAssessmentObjectsPublicShowAudience | null,
};

export default Vue.extend({
  name: 'SurveyeeAssessment',

  components: {
    SidebarUsersForRate,
    SurveyAssessment,
    NoDataStub,
    DialogAddingUsersForRate,
  },

  inject: ['RouteNames'],

  data() {
    return {
      couplesLoading: false,
      couplesData: {
        groups: [] as V1EntitiesCouplesPublicExpertIndexGroup[],
        couples: [] as V1EntitiesCouplesPublicExpertIndexCouple[],
      },
      assessmentLoading: false,
      assessment: { ...ASSESSMENT_DEFAULT_VALUE },
      rejectingLoading: false,
      completingLoading: false,
      showAddingDialog: false,
      isDemoMessageVisible: false,
      isSkipRedirectCheck: false,
      isAssessmentMessageVisible: false,
      answersLoading: false,
    };
  },

  computed: {
    ...mapGetters('survey', {
      surveyType: 'surveyType',
      is360Survey: 'is360Survey',
      currentSurveyStage: 'currentSurveyStage',
    }),
    ...mapState('survey', {
      surveyMetaRequest: 'metaRequest',
    }),

    /**
     * Режим тестового прохождения опроса.
     * Любые действия пользователя, приводящие к отправке на сервер, игнорируются
     * (но можно отмечать чекбоксы, выбирать варианты ответов и вводить данные без отправки на сервер)
     * */
    isTestingMode(): boolean {
      return 'testing' in (this.$route.query || {});
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    coupleId: {
      get(): number | null {
        return this.$route.params.coupleId ? Number(this.$route.params.coupleId) : null;
      },
      set(id: number | null) {
        if (id) {
          // Если попали по прямой ссылке, то не даем редиректить в оценку если этап не подходит
          if (
            !this.isSkipRedirectCheck
            && !this.isTestingMode
            && this.currentSurveyStage?.kind === SurveyStageKind.CHOOSING_EXPERTS
          ) {
            return;
          }
          if (this.$route.name === this.RouteNames.R_APP_SURVEYEE_ASSESSMENT) {
            this.$router.push({
              name: this.RouteNames.R_APP_SURVEYEE_ASSESSMENT,
              params: { coupleId: String(id) },
            });
          }
        } else {
          this.$router.push({ name: this.RouteNames.R_APP_ASSESSMENT });
        }
      },
    },
    currentCoupleIndex(): number {
      return this.couplesData.couples.findIndex((couple) => couple.id === this.coupleId);
    },
    currentCouple(): V1EntitiesCouplesPublicExpertIndexCouple | null {
      return this.currentCoupleIndex >= 0 ? this.couplesData.couples[this.currentCoupleIndex] : null;
    },
    nextNotRatedCouple(): V1EntitiesCouplesPublicExpertIndexCouple | null {
      // eslint-disable-next-line arrow-body-style
      const predicate = (couple: V1EntitiesCouplesPublicExpertIndexCouple) => {
        return (
          couple.id !== this.currentCouple?.id
          && [RatingStatusAliases.RUNNING, RatingStatusAliases.PENDING].includes(couple.status as RatingStatusAliases)
        );
      };

      // В приоритете - пары идущие после текущей
      const notRatedCouple = this.currentCoupleIndex
        ? this.couplesData.couples.slice(this.currentCoupleIndex + 1).find(predicate)
          || this.couplesData.couples.slice(0, this.currentCoupleIndex).find(predicate)
        : this.couplesData.couples.find(predicate);

      return notRatedCouple || null;
    },
    currentSurveyee(): V1EntitiesAudiencesPublicSearchIndexAudience | null {
      return this.assessment?.surveyee || null;
    },
    // Статусы пар пользователей на оценку для запроса
    requestedCouplesStatuses(): RatingStatusAliases[] {
      if (!this.is360Survey) {
        return [];
      }

      return [RatingStatusAliases.RUNNING, RatingStatusAliases.DONE, RatingStatusAliases.PENDING];
    },
    // Данные о парах для тестового режима прохождения
    testingCouples(): V1EntitiesCouplesPublicExpertIndex {
      const couples = [];
      if (this.currentSurveyee) {
        couples.push({
          id: 1,
          status: 'pending',
          groupId: 1,
          rejectedComment: '',
          surveyee: {
            ...this.currentSurveyee,
          },
          canReject: false,
        });
      }
      return {
        groups: [
          {
            id: 1,
            name: this.$t('assessmentSurvey.selfRating') as string,
            description: '',
            canAddSurveyee: false,
          },
        ],
        couples,
      };
    },
  },

  watch: {
    coupleId: {
      immediate: true,
      handler() {
        this.loadAssessment();
        this.scrollToTop();
      },
    },
    showAddingDialog(newValue) {
      if (!newValue) {
        this.loadCouples();
      }
    },
  },

  async mounted() {
    if (this.isTestingMode) {
      this.loadDemoAssessment();
    }
    if (this.surveyMetaRequest) {
      await this.surveyMetaRequest;
    }
    await this.loadCouples();
    if (!this.coupleId && this.nextNotRatedCouple) {
      this.coupleId = this.nextNotRatedCouple.id;
    }
  },

  methods: {
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    onAskForFeedbackClick() {
      if (this.isTestingMode) {
        this.isDemoMessageVisible = true;
      }
    },
    onOpenAddingDialogClick() {
      if (this.isTestingMode) {
        this.isDemoMessageVisible = true;
      } else {
        this.showAddingDialog = true;
      }
    },
    async loadCouples() {
      try {
        this.couplesLoading = true;

        const {
          data: { groups, couples },
        } = await tsxassApi.getV1CouplesIamExpert(this.surveyId, this.requestedCouplesStatuses);
        this.couplesData = { groups, couples };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.couplesLoading = false;
      }
    },
    async loadAssessment() {
      if (!this.coupleId) {
        this.assessment = { ...ASSESSMENT_DEFAULT_VALUE };
        return;
      }

      try {
        this.assessmentLoading = true;

        const {
          data: { groups, questions, surveyee },
        } = await tsxassApi.getV1CouplesCoupleIdAssessment(this.coupleId);
        this.assessment = { groups, questions, surveyee };
      } catch (err) {
        this.$router.push({ name: this.RouteNames.R_APP_ASSESSMENT_COUPLES });
      } finally {
        this.assessmentLoading = false;
      }
    },
    async loadDemoAssessment() {
      try {
        this.assessmentLoading = true;

        const {
          data: { groups, questions, surveyee },
        } = await tsxassApi.getV1DemoAssessment(this.surveyId);
        this.assessment = { groups, questions, surveyee };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.assessmentLoading = false;
      }
    },
    // Метод для организации очереди обновления опросов, для предотвращения спама обновлений
    answersQueue(fn: Function) {
      const KEY_DEBOUNCE_TIMEOUT = 1000;
      const QUEUE_TIMEOUT = 500;
      const questions = {};
      let debounceTimer: number | null = null;

      return (payload: PostV1CouplesCoupleIdAssessmentAnswer) => {
        clearTimeout(debounceTimer!);

        const { questionUuid } = payload;

        if (questionUuid) {
          questions[questionUuid] = payload;
        }

        let timerId: number | null = null;
        const requestsQueue: PostV1CouplesCoupleIdAssessmentAnswer[] = [];

        function queueRunner(args: PostV1CouplesCoupleIdAssessmentAnswer) {
          if (timerId) {
            requestsQueue.push(args);
            return; // уже есть активный таймер, ждем его окончания
          }

          requestsQueue.push(args);

          timerId = window.setTimeout(async () => {
            timerId = null; // сбрасываем таймер

            // выполняем отложенные действия
            while (requestsQueue.length) {
              // eslint-disable-next-line no-await-in-loop
              await fn(requestsQueue.shift());
              delete questions[args.questionUuid];
            }
          }, QUEUE_TIMEOUT);
        }

        // отправляем запросы, если таймер успел сработать (кликаем медленно)
        debounceTimer = window.setTimeout(() => {
          Promise.all(Object.values(questions).map(queueRunner));
        }, KEY_DEBOUNCE_TIMEOUT);
      };
    },
    async updateAnswers(payload: PostV1CouplesCoupleIdAssessmentAnswer & { coupleId?: number }) {
      if (this.isTestingMode) {
        return;
      }

      try {
        this.answersLoading = true;
        await tsxassApi.postV1CouplesCoupleIdAssessmentAnswer(payload.coupleId || this.coupleId!, payload);
        this.loadCouples();
      } catch (err) {
        const typedError = err as AxiosError;
        if (typedError?.response?.status !== 409) {
          errorHandler(err);
        }
        this.loadAssessment();
      } finally {
        this.answersLoading = false;
      }
    },
    async rejectOfAssessment(payload: CoupleRejectComment) {
      try {
        this.rejectingLoading = true;

        await tsxassApi.postV1CouplesCoupleIdRejected(payload.coupleId, { comment: payload.rejectedComment });
        this.loadCouples();
        this.coupleId = this.nextNotRatedCouple?.id || null;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.rejectingLoading = false;
      }
    },
    onDoneClick() {
      if (this.isTestingMode) {
        this.isDemoMessageVisible = true;
      } else {
        this.assessmentDone();
      }
    },
    async assessmentDone() {
      try {
        this.completingLoading = true;

        await tsxassApi.postV1CouplesCoupleIdAssessmentDone(this.coupleId!);
        this.loadCouples();
        this.isAssessmentMessageVisible = true;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.completingLoading = false;
      }
    },
  },
});
